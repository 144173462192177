// @ts-ignore
import NotFound from "../Assets/404 Error-bro.svg";
import "./Style.css";
import { useNavigate } from "react-router-dom";
import ButtonClick from "../Components/Buttons/ButtonClick.tsx";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const UndefinedRoute = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const handle = () => {
    navigate("/");
  };
  return (
    <>
      <div className={`undefined  ${isSmallScreen ? 'mobile-undefined' : ''} `}>
        <div className="notFound">
          <img src={NotFound} alt="Page not Found" />
        </div>
        <div className="go-back">
          <ButtonClick
            onClick={handle}
            name="Go to Home Page"
            variant="contained"
            className='back-btn'
            hover="#D5263F"
          />
        </div>

      </div>
    </>
  );
};
