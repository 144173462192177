import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
// @ts-ignore
const StyledButton = styled(Button)(({ hover }) => ({
  "&:hover": {
    backgroundColor: hover,
    boxShadow: '3px 3px 7px grey;',
  },
}));
// @ts-ignore
const ButtonClick = (props) => {
  const { type, sx, variant, className, name, onClick, hover, icon, disabled } =
    props;

  return (
    <>
      <StyledButton
        type={type}
        sx={sx}
        variant={variant}
        className={className}
        onClick={onClick}
        disabled={disabled}
        // @ts-ignore
        hover={hover}
        startIcon={icon}
      >
        {name}
      </StyledButton>
    </>
  );
};

export default ButtonClick;
