import React, { useState, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { UndefinedRoute } from "../Pages/UndefinedRoute.tsx";
// import Home from "../Pages/Home/Home";
import { Navigate } from "react-router-dom";
import Loader from "../Components/Loader.tsx";
import "../Components/Common.css";
// import EventFeedbackLogin from "../Pages/Home/EventFeedbackLogin.tsx";

type ErrorBoundaryProps = {
  children: any;
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
}: ErrorBoundaryProps) => {
  const [hasError, setHasError] = useState(false);

  const handleOnError = () => {
    setHasError(true);
  };

  return hasError ? (
    <div>Something went wrong...</div>
  ) : (
    <React.Fragment>
      {React.cloneElement(children, { onError: handleOnError })}
    </React.Fragment>
  );
};
const UniversityRoutes = React.lazy(() => import("./UniversityRoutes.tsx"));
const StudentRoutes = React.lazy(() => import("./StudentRoutes.tsx"));
const CountryDeskRoutes = React.lazy(() => import("./CountryDeskRoutes.tsx"));
const EventFeedbackLogin = React.lazy(() => import("./EventFeebackRoutes.tsx"));
const RoutePage = () => {
  return (
    <>
      <Suspense
        fallback={
          <div className="loading-box">
            <Loader size="2.5rem" />
            <div style={{ paddingTop: "15px" }}>Loading</div>
          </div>
        }
      >
        <Routes>
          <Route
            path="university/*"
            element={
              <ErrorBoundary>
                <UniversityRoutes />
              </ErrorBoundary>
            }
          />
          <Route
            path="student/*"
            element={
              <ErrorBoundary>
                <StudentRoutes />
              </ErrorBoundary>
            }
          />
          <Route
            path="country-desk/*"
            element={
              <ErrorBoundary>
                <CountryDeskRoutes />
              </ErrorBoundary>
            }
          />
          <Route
            path="event/*"
            element={
              <ErrorBoundary>
                <EventFeedbackLogin />
              </ErrorBoundary>
            }
          />
          <Route
            path="/event"
            element={<Navigate to="/event/feedback" replace={true} />}
          />
          <Route
            path="/"
            element={<Navigate to="/student/check-in" replace={true} />}
          />
          <Route path="*" element={<UndefinedRoute />} />
        </Routes>
      </Suspense>
    </>
  );
};
export default RoutePage;
