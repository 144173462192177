import React from "react";
import RoutePage from "./Routes/Routes.tsx";
import "./App.css";

function App() {
  return (
    <>
      <div className="App">
        <RoutePage />
      </div>
    </>
  );
}

export default App;
