import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loder({size}:any) {
  return (
    <Stack spacing={2} direction="row">
      <CircularProgress size={size} sx={{color: '#d5263f'}} />
    </Stack>
  );
}